/**
 * Рассчитывает контрастный цвет текста на основе цвета фона.
 * @param {string} backgroundColor - Цвет фона в формате HEX, RGB или RGBA.
 * @param {string} [pageBackgroundColor='#ffffff'] - Цвет подложки (по умолчанию белый).
 * @returns {string} - Контрастный цвет текста (#000000 или #ffffff).
 */
export const getContrastTextColor = (backgroundColor, pageBackgroundColor = '#ffffff') => {
    // Если цвет фона не передан или не является строкой, возвращаем значение по умолчанию
    if (!backgroundColor || typeof backgroundColor !== 'string') {
        return '#000000'; // Возвращаем черный цвет по умолчанию
    }

    // Преобразуем цвет фона в RGB или RGBA
    const parseColor = (color) => {
        // HEX
        if (color.startsWith('#')) {
            const hexToRgb = (hex) => {
                let hexColor = hex.startsWith('#') ? hex : `#${hex}`;
                const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
                hexColor = hexColor.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
                return result
                    ? {
                        r: parseInt(result[1], 16),
                        g: parseInt(result[2], 16),
                        b: parseInt(result[3], 16),
                        a: 1, // Прозрачность по умолчанию
                    }
                    : null;
            };
            return hexToRgb(color);
        }

        // RGBA
        if (color.startsWith('rgba')) {
            const rgba = color.match(/[\d.]+/g);
            if (rgba && rgba.length >= 3) {
                return {
                    r: parseInt(rgba[0], 10),
                    g: parseInt(rgba[1], 10),
                    b: parseInt(rgba[2], 10),
                    a: parseFloat(rgba[3] || 1), // Прозрачность
                };
            }
        }

        // RGB
        if (color.startsWith('rgb')) {
            const rgb = color.match(/[\d.]+/g);
            if (rgb && rgb.length >= 3) {
                return {
                    r: parseInt(rgb[0], 10),
                    g: parseInt(rgb[1], 10),
                    b: parseInt(rgb[2], 10),
                    a: 1, // Прозрачность по умолчанию
                };
            }
        }

        return null; // Если цвет не распознан
    };

    // Преобразуем цвет фона и подложки
    const bgColor = parseColor(backgroundColor);
    const pageColor = parseColor(pageBackgroundColor);

    if (!bgColor || !pageColor) return '#000000'; // Если цвет не распознан, возвращаем черный

    // Смешиваем цвета с учетом прозрачности
    const mixColors = (bg, page, alpha) => ({
        r: Math.round(bg.r * alpha + page.r * (1 - alpha)),
        g: Math.round(bg.g * alpha + page.g * (1 - alpha)),
        b: Math.round(bg.b * alpha + page.b * (1 - alpha)),
    });

    const finalColor = mixColors(bgColor, pageColor, bgColor.a);

    // Рассчитываем яркость (luminance)
    const luminance = (0.299 * finalColor.r + 0.587 * finalColor.g + 0.114 * finalColor.b) / 255;

    // Возвращаем контрастный цвет
    return luminance > 0.5 ? '#000000' : '#ffffff';
};

export const setDefaultHeaderColorTg = (tgWebApp, defColor, offStories = false, checkLP = false) => {
    const isLP = /^\/lp\/\w{22,}$/.test(window.location.pathname);

    if (tgWebApp && !offStories && tgWebApp?.isVersionAtLeast('6.1')) {
        // Если checkLP true и isLP false, и цвет хедера отличается от defColor
        if (checkLP && !isLP && tgWebApp?.headerColor !== defColor) {
            tgWebApp.setHeaderColor(defColor);
        }
        // Если checkLP false, и цвет хедера отличается от defColor
        else if (!checkLP && tgWebApp?.headerColor !== defColor) {
            tgWebApp.setHeaderColor(defColor);
        }
    }
};

// Вспомогательная функция для проверки корректности цвета
export const isValidColor = (color) => {
    if (!color) return false; // Проверяем, что цвет не пустой
    // Простая проверка на HEX-формат (#FFFFFF или #FFF) или RGB/RGBA
    return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color) ||
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(,\s*[\d.]+)?\)$/.test(color);
}

export const generateGradientColors = (baseColor) => {
    // Преобразуем HEX в HSL
    function hexToHsl(hex) {
        const r = parseInt(hex.slice(1, 3), 16) / 255;
        const g = parseInt(hex.slice(3, 5), 16) / 255;
        const b = parseInt(hex.slice(5, 7), 16) / 255;

        const max = Math.max(r, g, b);
            const min = Math.min(r, g, b);
        let h; let s; const l = (max + min) / 2;

        if (max === min) {
            // eslint-disable-next-line no-multi-assign
            h = s = 0; // Ахроматический (серый)
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            // eslint-disable-next-line default-case
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        return [h, s, l];
    }

    // Преобразуем HSL обратно в HEX
    function hslToHex(h, s, l) {
        function toHex(c) {
            const hex = Math.round(c * 255).toString(16);
            return hex.length === 1 ? `0${  hex}` : hex;
        }

        if (s === 0) {
            l = Math.round(l * 255);
            return `#${toHex(l)}${toHex(l)}${toHex(l)}`;
        }

        function hueToRgb(p, q, t) {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        const r = hueToRgb(p, q, h + 1 / 3);
        const g = hueToRgb(p, q, h);
        const b = hueToRgb(p, q, h - 1 / 3);

        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }

    // Получаем HSL из базового цвета
    const [h, s, l] = hexToHsl(baseColor);

    // Генерируем три цвета
    const color1 = hslToHex((h + 0.33) % 1, s, l); // +120°
    const color2 = baseColor; // Базовый цвет
    const color3 = hslToHex((h - 0.33 + 1) % 1, s, l); // -120°

    return [color1, color2, color3];
}

/**
 * Функция принимает HEX-цвет и процент осветления,
 * возвращая более светлый HEX-цвет.
 *
 * @param {string} hex - Исходный цвет в формате HEX (например, "#3498db" или "3498db")
 * @param {number} percent - Процент осветления (например, 20 для увеличения яркости на 20%)
 * @returns {string} - Новый цвет в формате HEX
 */
/**
 * Функция для осветления HEX-цвета на заданный процент.
 * @param {string} hex - исходный HEX-цвет (например, "#3498db")
 * @param {number} percent - процент осветления (например, 20)
 * @returns {string} - осветленный HEX-цвет
 */
export function lightenColor(hex, percent) {
    // Приводим hex к строке, если он не строка
    if (typeof hex !== 'string') {
        hex = String(hex);
    }

    // Убираем символ '#' если он есть
    hex = hex.replace(/^#/, '');

    // Если цвет задан в сокращённом виде (например, "abc"), преобразуем его в полную форму ("aabbcc")
    if (hex.length === 3) {
        hex = hex.split('').map(c => c + c).join('');
    }

    const num = parseInt(hex, 16);
    // eslint-disable-next-line no-bitwise
    let r = (num >> 16) & 0xff;
    // eslint-disable-next-line no-bitwise
    let g = (num >> 8) & 0xff;
    // eslint-disable-next-line no-bitwise
    let b = num & 0xff;

    // Осветляем каждый цветовой канал на заданный процент
    r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
    g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
    b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

    // Преобразуем компоненты обратно в HEX-строку
    const newHex = `#${  [r, g, b]
        .map(c => c.toString(16).padStart(2, '0'))
        .join('')
        .toUpperCase()}`;

    return newHex;
}


