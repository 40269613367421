import PropTypes from 'prop-types'
// import orderBy from 'lodash/orderBy'
// @mui
// import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
// import Avatar from '@mui/material/Avatar'
// import CardHeader from '@mui/material/CardHeader'
// import Typography from '@mui/material/Typography'
// import Card from '@mui/material/Card'
// utils
// import {fCurrency, fShortenNumber} from 'src/utils/format-number';
// components
// import Iconify from 'src/components/iconify'
// import List from "@mui/material/List"

// import IconButton from "@mui/material/IconButton"
// import ListItemAvatar from "@mui/material/ListItemAvatar"
// import ListItemText from "@mui/material/ListItemText"
// import ListItem from '@mui/material/ListItem/index'
// import Divider from "@mui/material/Divider"
// import { _folders } from "../../_mock"
import UserManagerReviewItem from "./user-manager-review-item"
import Scrollbar from "../../components/scrollbar/scrollbar"
import { HSLIDER_MAXLINE_WIDTH } from "../../config-global"
import { getWidthHSliderByCountItem } from "../../utils/utils"

// ----------------------------------------------------------------------

export default function UserManagerHscrollPanel({ title, subheader, list, shopid, content_type, maxItemInLine = 3, settingsView, sx, view, ...other }) {

    const arMinW = [{ len: 2, mw: 200 },
    { len: 1, mw: '90%' },
    { len: 3, mw: '40%' },]


    const curMinW = HSLIDER_MAXLINE_WIDTH.find(el => el.len === maxItemInLine)?.mw
    // console.log("settingsView" , settingsView);
    const DEBUG_MODE = false;

    const renderScroll = (
        <Scrollbar
            sx={{
                px:1.5,

            }}
        >
            <Stack direction="row" spacing={1.5} sx={{ pb: 1.5, }}>

                {/*  //* key={item?.id} // ❌ Возможная проблема: item.id может быть неопределенным или дублироваться */}
                {/* {list.map((item,index) => (
                    <UserManagerReviewItem
                        key={item?.id}
                        item={item}
                        type={content_type || item?.type}
                        column={settingsView?.column}
                        paper={settingsView?.paper}
                        showTitle={settingsView?.showTitle}
                        ratio={settingsView?.ratio}
                        sx={{
                            ...(list.length > maxItemInLine && {
                                minWidth: getWidthHSliderByCountItem(maxItemInLine),
                            }),
                            ...(list.length <= maxItemInLine && {
                                minWidth: getWidthHSliderByCountItem(maxItemInLine, 'max'),
                            }),
                            // ...(index === list.length - 1 && {
                            //     marginRight: 1,
                            // }),
                        }}
                        // sx={{
                        //     ...(list.length >= maxItemInLine && {
                        //         minWidth:  getWidthHSliderByCountItem(maxItemInLine) ,
                        //     }),
                        // }}
                        shopid={shopid}
                    />
                ))} */}


                {list.map((item,index) => {
                    // if (!item?.id) console.log(`У элемента с индексом ${index} отсутствует идентификатор! UserManagerReviewItem`, item);
                    if (DEBUG_MODE && !item?.id) console.log(`У элемента с индексом ${index} отсутствует идентификатор! UserManagerReviewItem1`, item);

                    return (
                        <UserManagerReviewItem

                            key={item?.id || `fallback-key-${index}`} // ✅ Безопасный запасной вариант
                            item={item}
                            type={content_type || item?.type}
                            column={settingsView?.column}
                            paper={settingsView?.paper}
                            showTitle={settingsView?.showTitle}
                            ratio={settingsView?.ratio}
                            sx={{
                                ...(list.length > maxItemInLine && {
                                    minWidth: getWidthHSliderByCountItem(maxItemInLine),
                                }),
                                ...(list.length <= maxItemInLine && {
                                    minWidth: getWidthHSliderByCountItem(maxItemInLine, 'max'),
                                }),
                                // ...(index === list.length - 1 && {
                                //     marginRight: 1,
                                // }),
                            }}
                            // sx={{
                            //     ...(list.length >= maxItemInLine && {
                            //         minWidth:  getWidthHSliderByCountItem(maxItemInLine) ,
                            //     }),
                            // }}
                            shopid={shopid}
                            view ={view}
                        />
                    );
                })}
            <Box
                sx={{
                    minWidth: '0.1px',
                    flexShrink: 0,
                }}
            />
            </Stack>
        </Scrollbar>)
    const renderGrid = (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            <Box gap={1} display="grid" gridTemplateColumns={{ xs: `repeat(${maxItemInLine}, 1fr)`, sm: `repeat(${maxItemInLine}, 1fr)` }}>
                {/* {list.map((item) => (
                    <UserManagerReviewItem
                        key={item?.id}
                        item={item}
                        type={content_type || item?.type}
                        column={settingsView?.column}
                        paper={settingsView?.paper}
                        showTitle={settingsView?.showTitle}
                        ratio={settingsView?.ratio}
                        sx={{
                            ...(maxItemInLine === 1 && {
                                minWidth: getWidthHSliderByCountItem(maxItemInLine, 'max'),
                            }),
                        }}
                        // sx={{
                        //     ...(list.length >= maxItemInLine && {
                        //         minWidth:  getWidthHSliderByCountItem(maxItemInLine) ,
                        //     }),
                        // }}
                        shopid={shopid}
                    />
                ))} */}

                {list.map((item,index) => {
                    // if (!item?.id) console.log(`У элемента с индексом ${index} отсутствует идентификатор! UserManagerReviewItem`, item);
                    if (DEBUG_MODE && !item?.id) console.log(`У элемента с индексом ${index} отсутствует идентификатор! UserManagerReviewItem2`, item);
                    return (
                        <UserManagerReviewItem

                            key={item?.id || `fallback-key-${index}`} // ✅ Безопасный запасной вариант
                            item={item}
                            type={content_type || item?.type}
                            column={settingsView?.column}
                            paper={settingsView?.paper}
                            showTitle={settingsView?.showTitle}
                            ratio={settingsView?.ratio}
                           sx={{
                            ...(maxItemInLine === 1 && {
                                minWidth: getWidthHSliderByCountItem(maxItemInLine, 'max'),
                            }),
                            }}
                            // sx={{
                            //     ...(list.length >= maxItemInLine && {
                            //         minWidth:  getWidthHSliderByCountItem(maxItemInLine) ,
                            //     }),
                            // }}
                            shopid={shopid}
                            view ={view}

                        />
                    );
                })}
            </Box>
        </>
    )
    return (
        <>
            {settingsView?.grid && renderGrid}
            {!settingsView?.grid && renderScroll}
        </>

    )
}

UserManagerHscrollPanel.propTypes = {
    list: PropTypes.array,
    subheader: PropTypes.string,
    title: PropTypes.string,
    shopid: PropTypes.string,
    content_type: PropTypes.string,
    maxItemInLine: PropTypes.number,
    settingsView: PropTypes.object,
    sx: PropTypes.object,
    view: PropTypes.string,
}

