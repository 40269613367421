import PropTypes, {string} from "prop-types";
import React, {useEffect} from "react";
import {Box} from "@mui/material";
import {getCardByShopId} from "../../../redux/slices/lp";
import {useDispatch, useSelector} from "../../../redux/store";
import UserManagerHscrollPanel from "../../user/user-manager-hscroll-panel";
import {useGetProducts} from "../../../api/product";
import {renderBlockHSlider , renderBlockContent} from "../../../utils/blocks";
import UserBannerProWidget from "../../user/user-banner-pro";
import UserCardTop from "../../user/user-card-top-head";

const allowBlocks = ['hslider' , 'banner_pro' , 'top_head' , 'category']

export default function ArticleUsercardBlockWidget({id , sx , shopid}){

    const {  curShopId   } = useSelector((state) => state.startform);
    const {  current_card , error ,  isLoading } = useSelector((state) => state.lp);
    const SHOP_ID = curShopId || shopid
    const {  all } = useGetProducts(SHOP_ID);

    const blocks = current_card?.blocks
    const currentBlock = blocks?.find(el => el.id === id)

    const curBlockType = currentBlock?.type || null
    const curContent = currentBlock?.content || {}

    const dispatch = useDispatch()

    const isShow = allowBlocks.includes(curBlockType)

    useEffect(() => {
        // dispatch(getLpById("2"))
        dispatch(getCardByShopId(SHOP_ID , null))
    }, [dispatch, SHOP_ID ])

    if(!isShow) return null
    return(
        <>
            {curBlockType === 'top_head' &&
                <Box
                    sx={{
                        mb: curContent?.buttonCount === 0 ? 0 : 4
                    }}
                >
                    <UserCardTop
                        user={current_card}
                        settings={curContent}
                        enableButtonLockingTop = {false}
                    />
                </Box>
            }
            <Box sx={sx}>
            {curBlockType === 'hslider' &&
                <UserManagerHscrollPanel list={renderBlockHSlider(curContent.items , all)}
                                         shopid={curShopId}
                                         settingsView={curContent.view}
                                         maxItemInLine={curContent.view?.column}

                />
            }
            {curBlockType === 'banner_pro' &&
                <UserBannerProWidget
                    // title={curContent?.title}
                    // text={curContent?.subheader}
                    // icon={curContent?.icon}
                    // iconPosition={curContent?.iconPosition}
                    // btnLink={curContent?.link}
                    // btnText={curContent?.btn_text}
                    // label={curContent?.label}
                    // iconOutside={curContent?.iconOutside}
                    // useBackgroundColor={curContent?.useBackgroundColor}
                    // startColor={curContent?.gradientStartColor}
                    // gradientDeg={curContent?.gradient_deg}
                    // endColor={curContent?.gradientEndColor}
                    // textColor={curContent?.textColor}
                    // usePikerColor={curContent?.usePikerColor}
                    // textLabelColor={curContent?.textLabelColor}
                    // useLabelPikerColor={curContent?.useLabelPikerColor}
                    // iconSize={curContent?.iconSize}
                    // borderRadius={curContent?.borderRadius}                  
                    // textOpacity={curContent?.textOpacity}
                    // textLabelOpacity={curContent?.textLabelOpacity}
                    // useImagine={curContent?.useImagine}
                    // imagine={curContent?.imagine}
                    // buttonStyle={curContent?.buttonStyle}
                    // sx={sxBlock}

                    settings={
                        curContent?.content
                    }
                />
            }

            {curBlockType === 'category' &&
                <UserManagerHscrollPanel list={curContent?.items}
                                         shopid={curShopId}
                                         settingsView={curContent?.view}
                                         maxItemInLine={curContent?.view?.column}
                                         content_type='category'
                />
            }
            </Box>
        </>
    )
}

ArticleUsercardBlockWidget.propTypes = {
    id: PropTypes.string ,
    sx: PropTypes.object ,
    shopid: PropTypes.string ,
}
