import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import {useRouter, useSearchParams} from 'src/routes/hooks';
//
import Box from "@mui/material/Box";
import { useAuthContext } from '../hooks';
import {setUtmForUser} from "../../redux/slices/startform";
import axios from "../../utils/axios";


const tg = window.Telegram.WebApp;
const tgData =  tg.initDataUnsafe;
const initData =  tg?.initData || '1234124reaedfadg3546qwer';
const probka = {
    id: "111111" ,
    first_name: "Евгений" ,
    last_name: "Кудряшов",
    username: "jonnnnster",
    photo_url: "/img",
    auth_date: "12.12.2022",
    hash: "1234124reaedfadg3546qwer"
}
// ----------------------------------------------------------------------

const loginPaths = {
    jwt: paths.auth.jwt.login,
};

// ----------------------------------------------------------------------

export default function AuthGuard({ children , userType }) {
    const router = useRouter();
    const getParams = useSearchParams();

    const get_id = getParams.get('id');
    const get_first_name = getParams.get('first_name');
    const get_last_name = getParams.get('last_name');
    const get_username = getParams.get('username');
    const get_photo_url = getParams.get('photo_url');
    const get_auth_date = getParams.get('auth_date');
    const get_hash = getParams.get('hash');
    const tgWebAppStartParam = getParams.get('tgWebAppStartParam');


    //  console.log("tgWebAppStartParam" , tgWebAppStartParam);
    let AUTH_GET_HTTP = null;
    if(get_id && get_first_name
        && get_last_name
        && get_username
        && get_photo_url
        && get_auth_date
        && get_hash)
    {
        AUTH_GET_HTTP = {
            id: get_id ,
            first_name: get_first_name ,
            last_name: get_last_name,
            username: get_username,
            photo_url: get_photo_url,
            auth_date: get_auth_date,
            hash: get_hash
        }
    }

    const { authenticated, method , tgLogin , unknownuser } = useAuthContext();

    const [checked, setChecked] = useState(false);

    // рефералка подписчиков
    let refId = null
    let utm = null

    // UTM
    const regexpUTM = /_utm([a-zA-Z0-9]+)/gm;
    const regexpRef = /_ref([0-9]+)/gm;
    if(tgWebAppStartParam) {
        // ?tgWebAppStartParam=a_5OhlUxyjwm5IJrSs2gjSie_back
        const arUtm = tgWebAppStartParam ? [...tgWebAppStartParam.matchAll(regexpUTM)] : []
        const arRefId = tgWebAppStartParam ? [...tgWebAppStartParam.matchAll(regexpRef)] : []

        if (arUtm.length > 0) {
            if (arUtm[0]?.input) {
                utm = arUtm[0][1];
            }
        }

        if(arRefId?.length > 0 ){
            if(arRefId[0]?.input){
                refId = arRefId[0][1];
            }
        }
    }
    const windowlocation = window.location.pathname

    useEffect(() => {
        console.log("authenticated from context:", authenticated);
    }, [authenticated]);
    // ========= Логинимся ==========
    useEffect(() => {

        const logError = async (data) => {
            console.log("data error", data);
            try {
                const response = await axios.post(`/auth-error`, data)

            } catch (error) {
                console.error(error)
            }
        }

        const authTg = async (data) => {
            try {
                await tgLogin(data);

            } catch (error) {
                // console.error(error);
            }
        }

        // eslint-disable-next-line no-prototype-builtins
        const emptyTgObj = tgData.hasOwnProperty("user");

        const shopid = getShopIdFromPathname(windowlocation);
        console.log("SHI", shopid);
        let authdata = {};
        if (process.env.NODE_ENV !== 'production') authdata = {...probka};
        if (AUTH_GET_HTTP) authdata = AUTH_GET_HTTP;
        if (emptyTgObj) authdata = {...tgData};

        // tgLogin(authdata);
// const loct = window.location.pathname || ''
        if (!authenticated) {
            if (process.env.NODE_ENV !== 'production') {
                const tgresp = {tg_response: authdata , type: userType }

                // Выполняем действия только если пользователь не авторизован
                authTg({...tgresp, ...(refId && {ref: refId}), ...(utm && {utm})})

            } else if (userType === "subscriber") {

                const tgResp = {tg_response: initData, shop_id: shopid, type: 'subscriber'}
                authTg({...tgResp, ...(refId && {ref: refId}), ...(utm && {utm})});

                if (shopid === "" || !shopid) logError({tg_response: tgResp, url: windowlocation})

            } else {
                const tgRespUser = {tg_response: initData, shop_id: shopid, type: 'user'}
                authTg({...tgRespUser});

                if (shopid === "" || !shopid) {
                    logError({tg_response: tgRespUser, url: windowlocation})
                }
                // authTg( { tg_response: authdata , shop_id: shopid , initData } );
            }
        }

    } , [AUTH_GET_HTTP, authenticated, refId, tgLogin, userType, utm, windowlocation]);
    // ==============================

    const check = useCallback(() => {
        if (!authenticated) {
            const searchParams = new URLSearchParams({
                returnTo: window.location.pathname,
            }).toString();

            // console.log("searchParams" , searchParams);

            const loginPath = loginPaths[method];
            const tgstart = tgWebAppStartParam ? `?tgWebAppStartParam=${tgWebAppStartParam}` : '';
            const href = `${loginPath}?${searchParams}${tgstart}`;

            router.replace(href);

        } else {
            setChecked(true);
        }
    }, [authenticated, method, router, tgWebAppStartParam]);

    useEffect(() => {
        check();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (!check) {
        return null;
    }

    return <>{children}</>;
}

AuthGuard.propTypes = {
    children: PropTypes.node,
    userType: PropTypes.string ,
};

function getShopIdFromPathname(strPathname) {
    // Шаблоны для извлечения shopid
    const patterns = [
        // Пути, где shopid идет сразу после префикса
        /^\/(?:shop|lp|event|games|product)\/([a-zA-Z0-9]{15,})(?:\/|\?|$)/,
        // Пути, где shopid идет после dashboard/...
        /^\/dashboard\/(?:shop|stories|usercard|general|pages|orders|analytics|notify|subscribers|referals|forms|quizzes|integrations|settings|pays)\/([a-zA-Z0-9]{15,})(?:\/|\?|$)/,
        // Пути, где shopid идет после dashboard/general/...
        /^\/dashboard\/general\/(?:shop|usercard|usercardchoice|settings|articles|tools|qs|chatbot|goods|pages|payment|paymentManual|promotions|affiliate|paymentAuto)\/([a-zA-Z0-9]{15,})(?:\/|\?|$)/,
        // Пути, где shopid идет после dashboard/notify/...
        /^\/dashboard\/notify\/(?:auto\/element\/)?([a-zA-Z0-9]{15,})(?:\/|\?|$)/,
        // Пути, где shopid идет после product/...
        /^\/product\/(?:shop|article)\/([a-zA-Z0-9]{15,})(?:\/|\?|$)/,
        // Новый шаблон для путей вида /dashboard/notify/7CYFCqhCnffSfBdghcOEzZ/list/
        /^\/dashboard\/notify\/([a-zA-Z0-9]{15,})\/list\//,
    ];

    // Перебираем шаблоны и ищем совпадение
    // eslint-disable-next-line no-restricted-syntax
    for (const pattern of patterns) {
        const match = strPathname.match(pattern);
        if (match) {
            const shopId = match[1];
            if (process.env.NODE_ENV === 'development') {
                console.log(`Extracted shopId from pathname "${strPathname}":`, shopId);
            }
            return shopId;
        }
    }

    // Если ничего не найдено
    if (process.env.NODE_ENV === 'development') {
        console.log(`No shopId found in pathname "${strPathname}"`);
    }
    return "";
}

// function getShopIdFromPathname(strPathname) {
//     const regx = /\/(?:product(?:\/article)?|lp|shop)\/([a-zA-Z0-9]+)(?:\/|\?|$)/;
//     // const regx = /\/(?:product(?:\/article)?|lp|shop|dashboard\/general\/articles)\/([a-zA-Z0-9]+)(?:\/|\?|$)/gm
//     const match = strPathname?.match(regx);
//     const res = match ? match[1] : "";
//     console.log("res" , res);
//     return  res // Возвращаем найденный shopid или пустую строку
// }

function _getShopIdFromPathname(strPathname){
    const regx = /\/(?:product(?:\/article)?|lp|shop)\/([a-zA-Z0-9]+)(?:\/|\?|$)/
    // /(\/|%2F)(lp|shop)(\/|%2F)([\w\d]+)?/gm

    // /\/lp\/([\w\d]+)/gm
    // console.log("strPathname" , strPathname );
    const arrFindStrShop = strPathname ? [...strPathname.matchAll(regx)] : [] ;
    let shopid = ""
    if(arrFindStrShop.length > 0 ){
        if(arrFindStrShop[0]?.length >= 5){
            shopid = arrFindStrShop[0][4]
        }
    }
    return shopid

}
