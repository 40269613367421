import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { palette } from '../palette';
import { shadows } from '../shadows';
import { customShadows } from '../custom-shadows';

// ----------------------------------------------------------------------

export function darkMode(mode) {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const settings = useSelector((state) => state?.shops?.settings?.settings);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  // Пути, где НЕ применяется кастомная тема
  const pagesWithCustomTheme = ['/lp', '/dashboard']; 

  // Проверяем, начинается ли путь с одного из указанных префиксов
  const isCustomThemePage = pagesWithCustomTheme.some((path) => location.pathname.includes(path));

  // Если мы на этих страницах, то отключаем customUser тему
  const useCustomUserTheme = settings?.useCustomColorBackground && !isCustomThemePage;


  const theme = {
    palette: palette(mode, useCustomUserTheme, settings),
    // palette: palette(mode, useCustomUserTheme, settings),
    shadows: shadows(mode),
    customShadows: customShadows(mode),
  };

  return theme;
}
