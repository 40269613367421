import PropTypes from 'prop-types';
import {useEffect, useReducer, useCallback, useMemo, useState} from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import {useRouter} from "../../../routes/hooks";
import {paths} from "../../../routes/paths";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
  error: null ,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children , params }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {shopid} = params ;
  const router = useRouter();

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get(endpoints.auth.me);

        const { user } = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...user,
              accessToken,
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const data = {
      email,
      password,
    };

    const response = await axios.post(endpoints.auth.login, data);

    const { accessToken, user } = response.data;

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // TG LOGIN
  // const tgLogin = useCallback(async (tg_response ) => {
  //   // const respData = {tg_response , shop_id: shopid} ; // {tg_response}
  //
  //   let _error = null
  //   const response = await axios.post('/auth', tg_response ,
  //       {params: {utm: tg_response?.utm , ref: tg_response?.ref }} ).catch((error) => {
  //     console.log("err" , error);
  //     if(error?.error === 'unknown user' && error?.bot){
  //       const pStart = error?.start || 's';
  //       router.replace(`/unknownuser/${error?.bot}/${pStart}`)
  //     }
  //     _error = error
  //   });
  //
  //
  //   if (response?.status !== 200) {
  //     router.replace(`/noauth`);
  //     dispatch({
  //       type: 'LOGIN',
  //       payload: {
  //         user: null,
  //         error: _error,
  //       },
  //     });
  //     return; // Прекращаем выполнение, если статус не 200
  //   }
  //
  //   const { accessToken, user } = response.data;
  //
  //   if(!accessToken){
  //     dispatch({
  //       type: 'LOGIN',
  //       payload: {
  //         user: null,
  //         error: _error
  //       },
  //     });
  //   }
  //
  //   setSession(accessToken);
  //
  //   dispatch({
  //     type: 'LOGIN',
  //     payload: {
  //       user,
  //     },
  //   });
  // }, [router]);

  // ----------------------------------------------------------------------

  const tgLogin = useCallback(async (tg_response) => {
    let _error = null;

    try {
      const response = await axios.post('/auth', tg_response, {
        params: { utm: tg_response?.utm, ref: tg_response?.ref },
      });

      // Проверяем ошибку "unknown user" в ответе сервера
      if (response?.data?.error === 'unknown user' && response?.data?.bot) {
        const pStart = response?.data?.start || 's';
        router.replace(`/unknownuser/${response?.data?.bot}/${pStart}`);
        return; // Прерываем выполнение после редиректа
      }

      // Проверяем статус ответа
      if (response.status !== 200) {
        router.replace(`/noauth`);
        dispatch({
          type: 'LOGIN',
          payload: {
            user: null,
            error: "Ошибка: статус ответа не 200",
          },
        });
        return; // Прерываем выполнение, если статус не 200
      }

      // Если всё успешно, продолжаем обработку
      const { accessToken, user } = response.data;

      if (!accessToken) {
        dispatch({
          type: 'LOGIN',
          payload: {
            user: null,
            error: "No access token received",
          },
        });
        return;
      }

      // Устанавливаем сессию и обновляем состояние
      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });

    } catch (error) {
      console.log("err >>>", error);
      _error = error;

      // Обработка ошибки "unknown user" в случае ошибки запроса
      if (error?.error === 'unknown user' && error?.bot) {
        const pStart = error?.start || 's';
        router.replace(`/unknownuser/${error?.bot}/${pStart}`);
        return; // Прерываем выполнение после редиректа
      }

      // Обработка других ошибок
      router.replace(`/noauth`);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: null,
          error: _error.message || "Произошла ошибка",
        },
      });
    }
  }, [router, dispatch]);

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;
  const responseError = state?.error || null

  const memoizedValue = useMemo(
      () => ({
        user: state.user,
        method: 'jwt',
        loading: status === 'loading',
        authenticated: status === 'authenticated',
        unauthenticated: status === 'unauthenticated',
        unknownuser: responseError?.error === 'unknown user' ? responseError?.bot : null ,
        //
        login,
        register,
        logout,
        tgLogin ,
      }),
      [login, logout, register, responseError?.bot, responseError?.error, state.user, status, tgLogin]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
  params: PropTypes.object ,
};
