import { createSlice } from '@reduxjs/toolkit';
// utils
import {isArray} from "lodash";
import axios from '../../utils/axios';

import {_mockLp} from '../../_mock/_lp'
import {_mockQuiz} from "../../_mock/_quiz";

// ----------------------------------------------------------------------


const initialState = {
    isLoading : false ,
    error: false ,
    current_quiz: [] ,
    current_quizzes: [] ,
    quizzes: [] ,
    user_answers: [] ,
    answer_del: false ,
};


const slice = createSlice({
    name: 'quizzes',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        stopLoading(state) {
            state.isLoading = false;
        },
        setLoading(state, action) {
            state.isLoading = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setQuizzesSuccess(state, action) {
            state.isLoading = false;
            state.quizzes =  action.payload ;
        },
        setCurrentQuiz(state, action) {
            state.isLoading = false;
            state.current_quiz =  action.payload ;
        },
        setCurrentQuizzes(state, action) {
            state.isLoading = false;
            state.current_quizzes =  [...state.current_quizzes , action.payload ];
        },
        setUserAnswers(state, action) {
            state.isLoading = false;
            state.user_answers =  action.payload ;
        },
        successDeleteItem(state , action){
            state.answer_del = true;
        } ,

        resetDeleteItemStatus(state , action){
            state.answer_del = false;
        } ,

    }
});

// Reducer
export default slice.reducer;

// Actions
export const {actions} = slice;

// ----------------------------------------------------------------------

export function setLoading(val){
    return (dispatch) => {
        dispatch(slice.actions.setLoading(val));
    }
}

export function getQuizzes(shopid ) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/quizzes/${shopid}`);
            const resp = response.data.data;
            const {success} = response.data;
console.log("Quizzes >>>" , resp);
            if(success ){
                dispatch(slice.actions.setQuizzesSuccess(resp));
            }
            // console.log("group_id" , group_id);

        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

export function getQuizID(shopid , formID , tg_id=null ) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/quizzes/${shopid}/${formID}?tg_id=${tg_id}`);
            console.log("response >>>" , response);
            const resp = response.data.data;
            const {success} = response.data;

            if(success ){
                dispatch(slice.actions.setCurrentQuiz(resp));
                dispatch(slice.actions.setCurrentQuizzes(resp));
            }else if(!success  && resp.error === 'user has already filled the quizz'){
                    dispatch(slice.actions.setCurrentQuiz({complete: true ,
                        final_text: resp.final_text ,
                        quiz_type: resp.form_type ,
                    }));

                dispatch(slice.actions.setCurrentQuizzes({
                    complete: true ,
                    final_text: resp.final_text ,
                    id: formID
                }));

                }

            // console.log("group_id" , group_id);


        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

// /forms/{shop_id}
export function createQuiz(shopid , data , formID=null , tg_id=null ) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`/quizzes/${shopid}` , data);
            const resp = response.data.data;
            const {success} = response.data;
            dispatch(slice.actions.stopLoading());
            // console.log("group_id" , group_id);

        } catch (e) {
            dispatch(slice.actions.hasError(e));
            dispatch(slice.actions.stopLoading());
        }
    }
}

// ===== ответы на форму юзера ====

export function getAnswersQuizzId(shopid , formID , tg_id=null) {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        const url = tg_id === null ? `/quizzes/${shopid}/${formID}/fillings` : `/quizzes/${shopid}/${formID}/fillings?tg_id=${tg_id}` ;
        try {
            const response = await axios.get(url);
            console.log("response 23" , response);
            const resp = response.data.data;
            // console.log("group_id" , group_id);
            dispatch(slice.actions.setUserAnswers(resp));

        } catch (e) {
            dispatch(slice.actions.hasError(e));
        }
    }
}

export function delAnswersById(shopid , formid , ids){
    // https://api.notibot.ru/{shop_id}/{form_id}/fillings/delete
    console.log("IDS" , ids);
    const arrIds = isArray(ids) ? ids : [] ;
    const user_ids = {user_ids: arrIds}
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        dispatch(slice.actions.resetDeleteItemStatus());
        try {
            const response = await axios.post(`/quizzes/${shopid}/${formid}/fillings/delete` , user_ids);
            console.log("response" , response);
            if(response?.data?.success){
                dispatch(slice.actions.successDeleteItem());
            }else{
                dispatch(slice.actions.hasError(`deleted error`));
            }
            dispatch(slice.actions.stopLoading());

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function setCurShop(shop){
    // console.log("CLICK" , shop);
    return (dispatch) => dispatch(slice.actions.setShopID(shop));
}



