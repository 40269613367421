import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CssBaseline from "@mui/material/CssBaseline";
import {Global} from "@emotion/react";
import Button from "@mui/material/Button"; // проверьте корректность пути до компонента Iconify
import {grey} from "@mui/material/colors";
import Iconify from '../../components/iconify';
import Markdown from "../../components/markdown";
import {lightenColor} from "../../utils/color";
import {invert} from "../../components/color-invert/color-invert";

// Определяем величину области для свайпа
const drawerBleeding = 26;

const StyledBox = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div', {
    // Не передаём пропс bgColor в DOM
    shouldForwardProp: (prop) => prop !== 'bgColor',
})(({ theme, bgColor }) => ({
    width: 30,
    height: 6,
    backgroundColor: bgColor || (theme.palette.mode === 'light' ? grey[300] : grey[900]),
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

/**
 * HeaderBox – контейнер для верхней области (где отображается puller).
 * Если передан пропс bgColor, используется его самый светлый оттенок (lightenColor(bgColor, 40)).
 */
const HeaderBox = styled('div', {
    // Не передаём bgColor в DOM
    shouldForwardProp: (prop) => prop !== 'bgColor',
})(({ theme, bgColor }) => ({
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: bgColor
        ? lightenColor(bgColor, 40)
        : (theme.palette.mode === 'light' ? '#fff' : grey[800]),
}));

// Новый компонент для основного контейнера с градиентным фоном
// Если передан bgColor, то вычисляем два его светлых варианта для градиента
const GradientContentBox = styled('div')(({ theme, bgColor }) => {

  if (bgColor) {
        const light40 = lightenColor(bgColor, 40); // верхний оттенок — на 40% светлее
        const light20 = lightenColor(bgColor, 20); // промежуточный — на 20% светлее
        return {
            background: `linear-gradient(180deg, ${light40}, ${light20}, ${bgColor})`,
        };
    }
    return {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
    };
});

/*
 * Компонент для вывода всплывающего окна (popup) снизу экрана.
 *
 * @param {boolean} open - состояние открытия popup
 * @param {function} onClose - функция для закрытия popup
 * @param {string} popupText - текст, который будет отображаться внутри всплывашки
 * @param {string|null} bgColor - кастомный цвет фона (HEX), если передан, используется для градиента
 * */

export default function UsercardPopup({ open, onClose, popupText, bgColor = '#fff' }) {
    return (
        <>
        <CssBaseline />
        <Global
            styles={{
                '.MuiDrawer-root > .MuiPaper-root': {
                    height: `calc(91% - ${drawerBleeding}px)`,
                    // height: `50%` ,
                    overflow: 'visible',

                },
            }}
        />

        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            onOpen={() => {}}
            swipeAreaWidth={drawerBleeding}
            ModalProps={{
                keepMounted: true, // ускоряет открытие, если компонент уже был отрендерен
            }}
        >
            {/* Верхняя область с ручкой для свайпа */}
            <HeaderBox
                bgColor={bgColor}
                sx={{
                    position: 'absolute',
                    top: -drawerBleeding,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    // visibility: 'visible',
                    right: 0,
                    left: 0,
                }}
            >
                <Puller bgColor={bgColor}/>
                <Box sx={{p:2}}/>
            </HeaderBox>

            {/* Основной контент всплывашки */}
            <GradientContentBox
                bgColor={bgColor}
                sx={{
                    pb: 2,
                    height: '100%',
                    overflow: 'auto',
                    color: (bgColor !== null && bgColor  !== '#fff') ? invert(bgColor, true)
                        : 'text.primary'
                }}
            >
                {/* Контент с прокруткой */}
                <Box
                    sx={{
                        px:2 ,
                        flexGrow: 1,
                        overflow: 'auto',
                        mb: 8, // добавляем отступ снизу, чтобы контент не перекрывал кнопку
                    }}
                >
                    <Markdown
                        children={popupText}
                        sx={{
                            '& p, li, ol': {
                                typography: 'body2',
                            },
                            '& ol': {
                                p: 0,
                                display: { md: 'flex' },
                                listStyleType: 'none',
                                '& li': {
                                    '&:first-of-type': {
                                        minWidth: 240,
                                        mb: { xs: 0.5, md: 0 },
                                    },
                                },
                            },
                        }}
                    />
                </Box>

                {/* Область с кнопкой закрытия, закрепленная внизу */}
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        backgroundColor: 'inherit',
                        p: 2,
                        width: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Button fullWidth variant="outlined" onClick={onClose}>
                        Закрыть
                    </Button>
                </Box>
            </GradientContentBox>

        </SwipeableDrawer>
        </>
    );
}

UsercardPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    popupText: PropTypes.string,
    bgColor: PropTypes.string, // может быть null
};

UsercardPopup.defaultProps = {
    popupText: '',
    bgColor: null,
};
