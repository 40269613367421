

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";


import {useSelector} from "../../redux/store";
import {useLocales} from "../../locales";
import { invert } from '../../components/color-invert/color-invert'

function mixColors(hex1, hex2, weight) {
    const d2h = (d) => d.toString(16).padStart(2, '0');
    const h2d = (h) => parseInt(h, 16);

    const color1 = hex1.startsWith('#') ? hex1.slice(1) : hex1;
    const color2 = hex2.startsWith('#') ? hex2.slice(1) : hex2;

    const mixed = `#${  [0, 2, 4].map(i =>
        d2h(Math.floor(h2d(color1.substr(i, 2)) * (1 - weight) + h2d(color2.substr(i, 2)) * weight))
    ).join("")}`;

    return mixed;
}


MadeByBottom.propTypes = {
    onOpen: PropTypes.func ,
}

export default function MadeByBottom({onOpen}){
    const {t} = useLocales();
    const {  curShopId   } = useSelector((state) => state.startform);
    const { settings , isLoading } = useSelector((state) => state.shops);
    const { current_card } = useSelector((state) => state.lp);

// console.log("is" , isAdvancedDemo);
    const isNotFree = settings?.tariff === 'advanced' || settings?.tariff === 'demo'
        || settings?.tariff === 'standard' ;


    // console.log("ya" , yaMetrika);

    const blocks = Array.isArray(current_card?.blocks) ? current_card.blocks : [];
    const specialBlock = blocks.find(block => block?.type === 'special_block') || null;
    const backgroundColorSpecialBlock = specialBlock?.content?.useBackgroundColor
        ? specialBlock.content.backgroundColor
        : '';

    const invertedColor = backgroundColorSpecialBlock ? invert(backgroundColorSpecialBlock, true) : '';
    const softerColor = invertedColor ? mixColors(invertedColor, "#808080", 0.2) : '';

    return (
        <>

            {!isNotFree &&
            <Button variant="text" onClick={onOpen} sx={{p:0.1}}>
                <Typography variant="subtitle2" component="div"
                    sx={{
                        // color: 'text.disabled'
                        color: softerColor
                    }}
                >
                    {t('footer.work_to_notibot')}
                </Typography>
            </Button>
            }

        </>

    );
}
