import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';
// @mui
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import {useSelector , useDispatch} from "../redux/store";

// locales
import { useLocales } from '../locales';
// components
import { useSettingsContext } from '../components/settings';
// system
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
import { customShadows } from './custom-shadows';
import { componentsOverrides } from './overrides';
// options
import { presets } from './options/presets';
import { darkMode } from './options/dark-mode';
import { contrast } from './options/contrast';
import RTL, { direction } from './options/right-to-left';
import {setDefaultHeaderColorTg} from "../utils/color";

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const { currentLang } = useLocales();

  const settings = useSettingsContext();

  const location = useLocation();

    const {tgWebApp} = settings;

    const fontFamily = useSelector((state) => state?.shops?.settings?.settings?.fontFamily);

  // Пути, где НЕ применяется кастомная тема
  const pagesWithCustomTheme = ['/dashboard']; 

  // Проверяем, начинается ли путь с одного из указанных префиксов
  const isCustomThemePage = pagesWithCustomTheme.some((path) => location.pathname.includes(path));

  const darkModeOption = darkMode(settings.themeMode);

  const presetsOption = presets(settings.themeColorPresets);

  const contrastOption = contrast(settings.themeContrast === 'bold', settings.themeMode);

  const directionOption = direction(settings.themeDirection);

  // const useCustomTypography = true

  // const customTypography = 'Raleway'

  // const primaryFont = useCustomTypography && fontFamily && !isCustomThemePage ? fontFamily : 'Public Sans, sans-serif';
  const primaryFont = fontFamily && !isCustomThemePage ? fontFamily : 'Public Sans, sans-serif';

  console.log('primaryFont', primaryFont , fontFamily,settings)

  const baseOption = useMemo(
    () => ({
      palette: palette('light'),
      shadows: shadows('light'),
      customShadows: customShadows('light'),
      // typography,
      typography: { ...typography, fontFamily: primaryFont },
      shape: { borderRadius: 8 },
    }),
    [primaryFont]
  );

  const memoizedValue = useMemo(
    () =>
      merge(
        // Base
        baseOption,
        // Direction: remove if not in use
        directionOption,
        // Dark mode: remove if not in use
        darkModeOption,
        // Presets: remove if not in use
        presetsOption,
        // Contrast: remove if not in use
        contrastOption.theme
      ),
    [baseOption, directionOption, darkModeOption, presetsOption, contrastOption.theme]
  );

  const theme = createTheme(memoizedValue);

  theme.components = merge(componentsOverrides(theme), contrastOption.components);

  const themeWithLocale = useMemo(
    () => createTheme(theme, currentLang.systemValue),
    [currentLang.systemValue, theme]
  );

  const defHeaderColor = theme.palette.background.default;

  setDefaultHeaderColorTg(tgWebApp , defHeaderColor , false , true )

  //
  //   if(tgWebApp?.headerColor !== defHeaderColor ){
  //     const isLP = /^\/lp\/\w{22,}$/.test(window.location.pathname)
  //     if (!isLP) {
  //         tgWebApp?.setHeaderColor(defHeaderColor);
  //     }
  // }

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <RTL themeDirection={settings.themeDirection}>
        <CssBaseline />
        {children}
      </RTL>
    </MuiThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
