import {CardMedia} from "@mui/material";
import ReactPlayer from 'react-player/youtube'
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Iconify from "../../components/iconify";

import Markdown from "../../components/markdown";
import ResponsivePlayer from "../../components/player/responsive-player";
import {useSettingsContext} from "../../components/settings";
import {useSelector} from "../../redux/store";
import {useLocales} from "../../locales";
import Image from "../../components/image";
import {isTgLink, selfLink} from "../../utils/article-self-link";
import { invert } from '../../components/color-invert/color-invert'

const tg = window.Telegram?.WebApp;

function mixColors(hex1, hex2, weight) {
    const d2h = (d) => d.toString(16).padStart(2, '0');
    const h2d = (h) => parseInt(h, 16);

    const color1 = hex1.startsWith('#') ? hex1.slice(1) : hex1;
    const color2 = hex2.startsWith('#') ? hex2.slice(1) : hex2;

    const mixed = `#${  [0, 2, 4].map(i =>
        d2h(Math.floor(h2d(color1.substr(i, 2)) * (1 - weight) + h2d(color2.substr(i, 2)) * weight))
    ).join("")}`;

    return mixed;
}

export default function FooterOferta (){
    const {t} = useLocales();
    const {  curShopId   } = useSelector((state) => state.startform);
    const {settings , isLoading } = useSelector((state) => state.shops);

    const { current_card } = useSelector((state) => state.lp);

    const blocks = Array.isArray(current_card?.blocks) ? current_card.blocks : [];
    const specialBlock = blocks.find(block => block?.type === 'special_block') || null;
    const backgroundColorSpecialBlock = specialBlock?.content?.useBackgroundColor
        ? specialBlock.content.backgroundColor
        : '';

    // const specialBlock = current_card?.blocks?.find(block => block?.type === 'special_block') || null;
    // const backgroundColorSpecialBlock = specialBlock?.content?.useBackgroundColor ? specialBlock?.content?.backgroundColor : '';
    //
    const handleClick = (link) => {
        if(link){
            const objLink = selfLink(link , curShopId);
            // console.log("objLink" , objLink);
            if(objLink?.type === 'location'){
                if(isTgLink(objLink?.link)){
                    tg.openTelegramLink(objLink?.link);
                }else{
                    window.location.href = objLink?.link;
                }
            }else if(objLink?.type === 'router'){
                window.location.href = objLink?.link;
            }
        }
    }
    // const handleClick = () => {
    //     if(settings?.public_offer){
    //         const objLink = selfLink(settings.public_offer , curShopId);
    //         // console.log("objLink" , objLink);
    //         if(objLink?.type === 'location'){
    //             if(isTgLink(objLink?.link)){
    //                 tg.openTelegramLink(objLink?.link);
    //             }else{
    //                 window.location.href = objLink?.link;
    //             }
    //         }else if(objLink?.type === 'router'){
    //             window.location.href = objLink?.link;
    //         }
    //     }
    // }

    const isNullOffer = (offer) => {
        if(offer === null || offer === "")return true;
        return false;
    }
// console.log("settings?." , settings?.settings?.footer_links);
    const footerLinks = settings?.settings?.footer_links;
    const invertedColor = backgroundColorSpecialBlock ? invert(backgroundColorSpecialBlock, true) : '';
    const softerColor = invertedColor ? mixColors(invertedColor, "#808080", 0.1) : '';
    return (
        <>
            {(!isLoading && !isNullOffer(footerLinks) ) &&
                <>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Stack direction="row" spacing={0.5} divider={<Divider orientation="vertical" flexItem />}>
                        {footerLinks?.public_offer &&
                            <Typography variant="caption" sx={{mt:1 , textAlign: "center",}}>
                                <Link
                                    sx={{
                                        color: softerColor
                                    }}
                                    onClick={() => handleClick(footerLinks?.public_offer)}
                                >
                                    {t('oferta')}
                                </Link>
                            </Typography>
                        }
                        {footerLinks?.policy_link &&
                            <Typography variant="caption" sx={{mt:1 , textAlign: "center"}}>
                                <Link
                                    onClick={() => handleClick(footerLinks?.policy_link)}
                                    sx={{
                                        color: softerColor
                                    }}
                                >
                                    {t('policy')}
                                </Link>
                            </Typography>
                        }
                        {footerLinks?.requisites_link &&
                            <Typography variant="caption" sx={{mt:1 , textAlign: "center"}}>
                                <Link
                                    onClick={() => handleClick(footerLinks?.requisites_link)}
                                    sx={{
                                        color: softerColor
                                    }}
                                >
                                    {t('requisites')}
                                </Link>
                            </Typography>
                        }
                    </Stack>



                </>

            }

        </>
    );
}
