import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { FastAverageColor } from 'fast-average-color'

// @mui
import { Card, Typography, Box } from '@mui/material'
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import { alpha, useTheme } from "@mui/material/styles"
import { common } from '@mui/material/colors'
import { bgGradient } from "../../theme/css"
import Label from "../../components/label"
import Markdown from "../../components/markdown"
import ArticleMarkdownOL from "../article/articles/article-markdown-ol"
import Iconify from "../../components/iconify"

import { invert, invertAsRGB, invertAsRgbArray, rgbaToHex } from '../../components/color-invert/color-invert'
import { ArticleMarkdownButton } from "../article/articles/article-markdown-button"
import { useSettingsContext } from "../../components/settings"
import { TG_NULL_OBJECT } from "../../config-global"

// utils


// ----------------------------------------------------------------------

UserBannerProWidget.propTypes = {
    settings: PropTypes.object,
    sx: PropTypes.object,
}


const TgNullObj = process.env.NODE_ENV !== 'production' ? TG_NULL_OBJECT : null


const DEFAULT_SETTINGS = {
    iconPosition: 'end',
    iconSize: 90,
    color: 'black',
    textLabelOpacity: 100,
    iconWidth: 94,
    textOpacity: 100,
    gradient_deg: 120,
    useImagine: false,
    useBackgroundColor: false,
    useLabelPikerColor: false,
    usePikerColor: false,
    borderRadius: 0,
    variant: 'grd90',
    buttonStyle: {
        useBackgroundButtonColor: false,
        useTextButtonColor: false,
        backgroundColor: 'transparent',
        textColor: '#000000',
    },
}

// export default function UserBannerProWidget({ title, text, icon, iconPosition = 'end', iconSize = 90,
//     btnLink, btnText, color = 'black', imagine,useImagine,useBackgroundColor, gradientStartColor, gradientEndColor, gradientDeg, textLabelColor, textLabelOpacity = 100, useLabelPikerColor,
//     variant, buttonClick, iconWidth = 94, textMarkdown, textForOl, textColor, textOpacity  = 100, usePikerColor, label, buttonStyle, borderRadius, sx, ...other }) {
// export default function UserBannerProWidget({settings, sx, ...other }) {
export default function UserBannerProWidget({ settings: userSettings, sx, ...other }) {

    const settings = { ...DEFAULT_SETTINGS, ...userSettings }
    // console.log('settings',settings)

    const { tgWebApp } = useSettingsContext()
    const theme = useTheme()
    const showBtn = (settings?.link !== null && settings?.link !== '')

    // const adjustedIconWidth = showBtn ? iconWidth * 1.6 : iconWidth * 1.4
    const marginTopValue = showBtn ? 1 : 2

    const [calculatedTextColor, setCalculatedTextColor] = useState('#FFFFFF')
    const [calculatedBlockLabelTextColor, setCalculatedBlockLabelTextColor] = useState('#000000')
    const [calculatedBackgroundColor, setCalculatedBackgroundColor] = useState('rgba(255, 255, 255, 0.2)')


    const cover = ''


    useEffect(() => {

        const updateColorsFromGradient = () => {
            if (settings?.gradientStartColor && settings?.gradientEndColor && settings?.gradient_deg) {
                const deg = parseInt(settings?.gradient_deg, 10)
                const isLeftAligned = settings?.iconPosition === 'start'

                // Логика выбора цвета в зависимости от положения и угла градиента
                const primaryColor = (isLeftAligned && deg >= 181 && deg <= 319) || (!isLeftAligned && (deg >= 0 && deg <= 180 || deg >= 320 && deg <= 360))
                    ? settings?.gradientStartColor
                    : settings?.gradientEndColor

                // Инвертируем выбранный цвет с учетом WCAG
                const invertedColor = invert(primaryColor, true, 'AA', 'regularText')
                const backgroundColor = alpha(invert(primaryColor, false), 0.2)

                const primaryColorHex = rgbaToHex(backgroundColor)
                // console.log('primaryColorHex',primaryColorHex)
                const invertedColorBlockLabel = invert(primaryColorHex, true, 'AA', 'regularText')


                // console.log('Primary Color based on Position and Degree:', primaryColor)
                //  console.log('Inverted Color with WCAG:', invertedColor)
                // console.log('Background Color:', backgroundColor)

                setCalculatedTextColor(invertedColor)
                setCalculatedBackgroundColor(backgroundColor)
                setCalculatedBlockLabelTextColor(invertedColorBlockLabel)
            }
        }

        updateColorsFromGradient()

    }, [cover, settings?.gradientStartColor, settings?.gradientEndColor, settings?.gradient_deg, settings?.iconPosition])

    const blockLabel = (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ mt: 1 }}
        // sx={{ position: 'absolute', zIndex: 9, top: 16, right: 16 }}
        >
            <Label
                variant="soft"
                // color="secondary"
                sx={{
                    color: settings?.useLabelPikerColor ? settings?.textLabelColor : calculatedBlockLabelTextColor,
                    backgroundColor: calculatedBackgroundColor || 'black',
                    opacity: (settings?.textLabelOpacity ?? 100) / 100,
                }}
            >
                {settings?.label}
            </Label>

        </Stack>

    )


    const renderIcon = (

        <Box
            sx={{
                pl: 0.5,
                width: settings?.iconSize,
                height: settings?.iconSize,
                lineHeight: 0,
                borderRadius: '50%',
                position: 'absolute',
                zIndex: 1,
                left: settings?.iconPosition === 'start' ? '0' : 'auto',
                right: settings?.iconPosition === 'end' ? '0' : 'auto',
            }}
        >
            {/* <Iconify icon={icon} width={iconOutside ? adjustedIconWidth : iconWidth} /> */}
            {/* <Iconify icon={icon} width={iconSize} /> */}

            {settings?.icon?.startsWith('<?xml') ? (
                // Если icon — это SVG-код, используем dangerouslySetInnerHTML для вставки SVG
                <span
                    style={{
                        display: 'inline-block',
                        width: settings?.iconSize,
                        height: settings?.iconSize,
                    }}
                    dangerouslySetInnerHTML={{ __html: settings?.icon }}
                />
            ) : (
                // Если icon — это имя иконки, используем компонент Iconify
                <Iconify icon={settings?.icon} width={settings?.iconSize} height={settings?.iconSize} />
            )}

        </Box>
    )

    const textOl = settings?.textForOl?.length > 0

    let typographyVariant = "h5"

    if (settings?.iconSize > 100) {
        typographyVariant = "h5"
    }
    if (settings?.iconSize > 120) {
        typographyVariant = "body1"
    }
    if (settings?.iconSize > 148) {
        typographyVariant = "body2"
    }

    const renderContent = (
        <Stack
            spacing={1}
            sx={{
                flex: 1,
                maxWidth: `calc(100% - ${settings?.iconSize}px)`,
            }}
        >
            <Stack spacing={1}
                sx={{
                    mt: settings?.label ? 0 : 1
                }}
            >
                {settings?.label && blockLabel}
                <Typography
                    variant={typographyVariant}
                    fontWeight={700}
                    sx={{ color: settings?.usePikerColor ? settings?.textColor : calculatedTextColor, opacity: (settings?.textOpacity ?? 100) / 100 }}
                >
                    {settings?.title}
                </Typography>
                <Typography variant="caption" sx={{ color: settings?.usePikerColor ? settings?.textColor : calculatedTextColor, fontSize: 14, lineHeight: 1.2 }}>
                    {settings?.subheader}
                </Typography>
            </Stack>
            {showBtn &&
                // <Button variant="contained" color="warning" onClick={() => buttonClick()}>{btnText}</Button>
                <ArticleMarkdownButton text={settings?.btn_text} link={settings?.link} tgUser={tgWebApp?.initDataUnsafe?.user || TgNullObj} banner
                    sx={{
                        '& .MuiButton-root': {
                            backgroundColor: settings?.buttonStyle?.useBackgroundButtonColor
                                ? settings?.buttonStyle?.backgroundColor : "transportal",
                            // color: buttonStyle?.textColor,     
                            color: settings?.buttonStyle?.useTextButtonColor
                                ? settings?.buttonStyle?.textColor
                                : "#000000",
                        },
                    }}
                />
            }
        </Stack >
    )

    let justifyContentValue
    if (settings?.iconPosition === 'start') {
        justifyContentValue = 'end'
    } else {
        justifyContentValue = 'start'
    }
    // console.log('imagine',settings?.imagine)

    return (
        <Box
            {...other}
            sx={sx}
        >
            <Card
                sx={{
                    overflow: 'visible',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: justifyContentValue,
                    // justifyContent: iconPosition === 'start' ? (iconOutside ? 'start' : 'space-between') : (iconOutside ? 'end' : 'space-between'),
                    ...(settings?.variant === 'grd90' && {
                        boxShadow: 'none',
                    }),
                    ...(settings?.variant === 'ghost' && {
                        borderWidth: 1,
                        borderStyle: 'dashed',
                        borderColor: theme.palette.divider,
                    }),

                    py: showBtn ? 2 : 0.5,
                    px: 2,
                    ...(!showBtn && {
                        pb: 2
                    }),

                    // pl: 2,
                    color: settings?.color,
                    position: 'relative',

                    // eslint-disable-next-line no-nested-ternary
                    backgroundImage: settings?.useImagine
                        ? `url(${settings?.imagine}), linear-gradient(${typeof settings?.gradient_deg === 'string'
                            ? settings?.gradient_deg.replace(/[^0-9.]/g, '')
                            : settings?.gradient_deg || 120}deg, ${settings?.gradientStartColor}, ${settings?.gradientEndColor})`
                        : settings?.useBackgroundColor
                            ? `linear-gradient(${typeof settings?.gradient_deg === 'string'
                                ? settings?.gradient_deg.replace(/[^0-9.]/g, '')
                                : settings?.gradient_deg || 120}deg, ${settings?.gradientStartColor}, ${settings?.gradientEndColor})`

                            : 'none',
           
                    backgroundSize: settings?.useImagine ? 'cover' : undefined,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',

                    // eslint-disable-next-line object-shorthand
                    borderRadius: settings?.borderRadius,

                }}
                {...other}
            >

                {settings?.iconPosition === 'start' &&
                    <>
                        {settings?.icon !== null && renderIcon}
                        {renderContent}
                    </>
                }

                {settings?.iconPosition === 'end' &&
                    <>
                        {renderContent}
                        {settings?.icon !== null && renderIcon}
                    </>
                }



            </Card>
        </Box>
    )
}


const hexToRgb = (hex) => {
    // console.log('hexhex', hex)
    if (!hex || typeof hex !== "string") return "0, 0, 0"

    let r = 0; let g = 0; let b = 0
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16)
        g = parseInt(hex[2] + hex[2], 16)
        b = parseInt(hex[3] + hex[3], 16)
    } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16)
        g = parseInt(hex[3] + hex[4], 16)
        b = parseInt(hex[5] + hex[6], 16)
    }
    return `${r}, ${g}, ${b}`
}
