// src/redux/slices/funnel_stats.js
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
    isLoading: false,
    error: null,
    current_funnel: null,       // текущая выбранная воронка
    current_funnels: [],        // список воронок, полученных при загрузке отдельной воронки
    isSaved: false,
    funnels: [],                // общий список воронок
};

const slice = createSlice({
    name: 'funnel_stats',
    initialState,
    reducers: {
        // Установка статуса загрузки
        startLoading(state) {
            state.isLoading = true;
        },
        stopLoading(state) {
            state.isLoading = false;
        },
        // Флаг успешного сохранения
        setIsSaved(state) {
            state.isSaved = true;
        },
        resetIsSaved(state) {
            state.isSaved = false;
        },
        // Обработка ошибки
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        // Установка списка воронок (GET /funnels/{{shop_id}})
        setFunnelsSuccess(state, action) {
            state.isLoading = false;
            state.funnels = action.payload;
        },
        // Установка текущей воронки (GET /funnels/{{shop_id}}/{{funnel_id}})
        setCurrentFunnel(state, action) {
            state.isLoading = false;
            state.current_funnel = action.payload;
        },
        // Добавление в список текущих воронок (при получении воронки)
        setCurrentFunnels(state, action) {
            state.isLoading = false;
            state.current_funnels = [...state.current_funnels, action.payload];
        },
        // Удаление воронки из списка (DELETE)
        removeFunnel(state, action) {
            state.funnels = state.funnels.filter((funnel) => funnel.id !== action.payload);
            if (state.current_funnel && state.current_funnel.id === action.payload) {
                state.current_funnel = null;
            }
            state.isLoading = false;
        },
    },
});

export default slice.reducer;
export const {
    startLoading,
    stopLoading,
    setIsSaved,
    resetIsSaved,
    hasError,
    setFunnelsSuccess,
    setCurrentFunnel,
    setCurrentFunnels,
    removeFunnel,
} = slice.actions;

// Получение общего списка воронок: GET /funnels/{{shop_id}}
export function getFunnels(shopid) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await axios.get(`/funnels/${shopid}`);
            const resp = response.data.data;
            if (response.data.success) {
                dispatch(setFunnelsSuccess(resp));
            }
        } catch (e) {
            dispatch(hasError(e));
        }
    };
}

// Получение конкретной воронки: GET /funnels/{{shop_id}}/{{funnel_id}}

export function getFunnelID(shopid, funnelId, statistic = false, filters = {}) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const queryParams = [];
            if (statistic) {
                queryParams.push('statistic=1');
                // Добавляем start_date и end_date только если оба присутствуют
                if (filters.startDate && filters.endDate) {
                    queryParams.push(`start_date=${filters.startDate}`);
                    queryParams.push(`end_date=${filters.endDate}`);
                }
            }
            const queryString = queryParams.length > 0 ? `?${  queryParams.join('&')}` : '';
            const url = `/funnels/${shopid}/${funnelId}${queryString}`;
            const response = await axios.get(url);
            console.log(">>>", response.data);
            if (response.data.success) {
                dispatch(setCurrentFunnel(response.data.data));
                dispatch(setCurrentFunnels(response.data.data));
            } else {
                dispatch(hasError(response.data.data)); // response.data.data – текст ошибки
            }
        } catch (e) {
            dispatch(hasError(e));
        }
    };
}
// Создание новой воронки: POST /funnels/{{shop_id}}
export function createFunnel(shopid, data) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await axios.post(`/funnels/${shopid}`, data);
            console.log("response" , response);
            const resp = response.data.data;
            if (response.data.success) {
                dispatch(setCurrentFunnel(resp));
                dispatch(setIsSaved());
            }
            dispatch(stopLoading());
        } catch (e) {
            dispatch(hasError(e));
            dispatch(stopLoading());
        }
    };
}

// Обновление воронки: PATCH /funnels/{{shop_id}}/{{funnel_id}}
export function updateFunnel(shopid, funnelId, data) {
    console.log("data >>" , data);
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await axios.patch(`/funnels/${shopid}/${funnelId}`, data);
           console.log(">>resp >>" , response);
            if (response.data.success) {
                dispatch(setCurrentFunnel(response.data.data));
            } else {
                dispatch(hasError(response.data.data)); // response.data.data содержит текст ошибки
            }
            dispatch(stopLoading());
        } catch (e) {
            dispatch(hasError(e));
            dispatch(stopLoading());
        }
    };
}

// Удаление воронки: DELETE /funnels/{{shop_id}}/{{funnel_id}}
export function deleteFunnel(shopid, funnelId) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await axios.delete(`/funnels/${shopid}/${funnelId}`);
            if (response.data.success) {
                dispatch(removeFunnel(funnelId));
            } else {
                dispatch(hasError(response.data.data)); // Текст ошибки
            }
            dispatch(stopLoading());
        } catch (e) {
            dispatch(hasError(e));
            dispatch(stopLoading());
        }
    };
}
